import styled from 'styled-components'

const DetailsScrollContainer = styled.div`
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  padding-bottom: 20px;

  @media (max-width: 850px) {
    height: auto;
    overflow-y: unset;
  }
`

const DetailContainer = styled.div`
  margin: 10px 10px 20px 10px;
`

const HiddenInput = styled.input`
  margin: 10px 0;
  padding: 5px;
  width: 100%;
  cursor: copy;
`

export { DetailContainer, HiddenInput, DetailsScrollContainer }
