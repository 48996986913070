import styled from 'styled-components'

import {
  colours,
  font,
  fontSize,
  viewportBreakpoints,
} from '../../../../_globals/theme'

const NavContainer = styled.nav`
  width: 100%;
  border-top: ${props => props.theme.borderTop};
  border-bottom: ${props => props.theme.borderBottom};
  z-index: 100;
  position: relative;
  background-color: rgb(217, 217, 255);
  display: block;
`
NavContainer.defaultProps = {
  theme: {
    borderTop: 'none',
    borderBottom: 'none',
    height: '90px',
  },
}

const NavContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const NavItem = styled.button`
  width: 100%;
  height: 60px;
  border: none;
  background: none;
  background-color: ${colours.background};
  cursor: pointer;
  color: white;

  &:not(:last-of-type) {
    border-right: 1px solid black;
  }

  &.active {
    background-color: ${colours.highlight};
  }
`

const DoubleRow = styled.div`
  display: inline-block;
  vertical-align: middle;
  flex: 1;
`

const LogoContainer = styled.div`
  flex: 1;

  @media only screen and (max-width: 650px) {
    width: 100%;
    text-align: center;
    flex: 1;
  }
`

const LargeLogo = styled.img`
  max-height: 70px;
  margin: auto;
  max-width: 90%;
  padding: 10px;
  vertical-align: middle;
  cursor: pointer;
  min-width: 350px;

  @media only screen and (max-width: 650px) {
    max-height: 50px;
    min-width: unset;
  }
`

const SmallLogo = styled.img`
  vertical-align: middle;
  height: 35px;
  cursor: pointer;
`

const SearchContainer = styled.div`
  display: flex;
  max-width: 700px;
  margin: auto;
  padding-top: 20px;
  position: relative;

  @media only screen and (max-width: ${viewportBreakpoints.phone[1]}px) {
    padding: 0 10px 0 10px;
    margin-top: -11px;
  }
`

const SearchText = styled.p`
  background-color: black;
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 5px 10px 5px 20px;
  font-size: 0.9rem;
  margin: 5px -3px 5px 5px;
  height: 19px;
  width: 100px;
  cursor: default;

  @media only screen and (max-width: ${viewportBreakpoints.phone[1]}px) {
    margin-left: 0;
    border-bottom-left-radius: 0;
  }
`

const SearchBoxContainer = styled.div`
  flex: 1;
`

const SearchInput = styled.input`
  background-color: #000000;
  color: #ffffff;
  width: calc(100% - 22px);
  border: 1px solid black;
  padding: 5px 10px;
  margin-top: 5px;
  padding-left: 20px;
  margin-left: -10px;

  @media only screen and (max-width: ${viewportBreakpoints.phone[1]}px) {
    margin-left: 0;
    border-bottom-left-radius: 5px;
  }
`

const DropdownContainer = styled.div`
  position: relative;

  @media only screen and (max-width: ${viewportBreakpoints.phone[1]}px) {
    flex: 1;
    border-bottom-right-radius: 0;
  }
`

const SearchIcon = styled.div`
  background-color: black;
  padding: 5px 20px;
  margin: 5px 5px 5px -2px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  height: 19px;

  @media only screen and (max-width: ${viewportBreakpoints.phone[1]}px) {
    width: 50px;
    text-align: center;
    border-top-right-radius: 0;
  }
`

const CartContainer = styled.button`
  display: inline-block;
  height: 100%;
  width: 100px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  background: none;
  border: none;

  @media only screen and (max-width: ${viewportBreakpoints.phone[1]}px) {
    width: 100%;
    height: 45px;
    padding-top: 5px;
  }
`

const CartIcon = styled.div`
  display: block;
  margin: 5px;

  @media only screen and (max-width: ${viewportBreakpoints.phone[1]}px) {
    display: inline-block;
    vertical-align: middle;
  }
`

const CartText = styled.p`
  display: block;
  color: white;
  font-family: ${font.regular};
  font-size: ${fontSize.small};

  @media only screen and (max-width: ${viewportBreakpoints.phone[1]}px) {
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px;
  }
`

const LinkContainer = styled.div`
  display: flex;

  @media only screen and (max-width: 650px) {
    width: 100%;
    justify-content: center;
    padding: 15px 0;
  }
`

export {
  NavContainer,
  NavItem,
  LargeLogo,
  SmallLogo,
  NavContentContainer,
  SearchContainer,
  SearchBoxContainer,
  SearchText,
  SearchIcon,
  DoubleRow,
  CartContainer,
  CartIcon,
  CartText,
  LinkContainer,
  SearchInput,
  DropdownContainer,
  LogoContainer,
}
