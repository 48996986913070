import React, { useMemo, useCallback } from 'react'
import {
  faClipboardList,
  faQuestionCircle,
  faSignInAlt,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons'
import { DefaultTheme } from 'styled-components'
import { useNavigate } from 'react-router'

import LogoLong from '../../../../_assets/images/logo.png'

import { RootState } from '../../../../_globals/state-store'
import { useAppSelector } from '../../../../_globals/hooks'
import { NavBarProps } from './types'
import {
  NavContainer,
  NavContentContainer,
  LargeLogo,
  LogoContainer,
  LinkContainer,
} from './styled'
import NavLink from './components/nav-link/NavLink'

const userSelector = (state: RootState) => state.user
const themeAppSelector = (state: RootState) => state.theme

/**
 * The navbar portion of the NavMenu
 * @see NavMenu
 *
 * @param {NavBarProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <NavBar
 *  pagePosition="bottom"
 *  hideCartButton={hideCartButton || false}
 *  onCartClick={() => handleCartClick()}
 * />
 * ```
 */
const NavBar = ({ pagePosition, hideCartButton }: NavBarProps): JSX.Element => {
  const navigation = useNavigate()
  const user = useAppSelector(userSelector)
  const themeSelector = useAppSelector(themeAppSelector)

  const isSignedIn = useMemo(() => user && user?.id !== '', [user])

  const containerTheme = useMemo(() => {
    const theme: DefaultTheme = {
      borderTop: 'none',
      borderBottom: 'none',
      height: '70px',
    }

    if (themeSelector.isPhoneView) {
      if (hideCartButton) {
        theme.height = '110px'
      } else if (hideCartButton !== true) {
        theme.height = '135px'
      }
    }

    if (pagePosition === 'top') {
      theme.borderBottom = `2px solid rgb(217, 217, 255)`
    } else {
      theme.borderTop = `2px solid rgb(217, 217, 255)`
    }

    return theme
  }, [themeSelector.isPhoneView, pagePosition, hideCartButton])

  const handleLinkClick = useCallback(
    (url: string) => {
      navigation(url)
    },
    [navigation],
  )

  return (
    <NavContainer theme={containerTheme} id="main_navigation_bar">
      <NavContentContainer>
        <LogoContainer>
          <LargeLogo src={LogoLong} onClick={() => handleLinkClick('/home')} />
        </LogoContainer>
        <LinkContainer>
          {isSignedIn ? (
            <>
              <NavLink
                icon={faClipboardList}
                title="Cases"
                url="/"
                activeUrls={['/case/']}
              />
              <NavLink
                icon={faUserCircle}
                title="Profile"
                url="/profile"
                activeUrls={['/profile']}
              />
              <NavLink
                icon={faQuestionCircle}
                title="Help"
                url="/help"
                activeUrls={['/help']}
              />
            </>
          ) : (
            <NavLink
              icon={faSignInAlt}
              title="Sign In"
              url="/login"
              activeUrls={['/login']}
            />
          )}
        </LinkContainer>
      </NavContentContainer>
    </NavContainer>
  )
}

export default NavBar
