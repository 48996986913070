import React from 'react'

import PageContainer from '../../components/page-container/PageContainer'
import TextElement from '../../components/text/Text'
import Spacer from '../../components/spacer/Spacer'
import AlignmentContainer from '../../components/alignment-container/AlignmentContainer'
import {
  Bold,
  ContentContainer,
  Heading,
  ImageElement,
  Link,
  Paragraph,
} from './styled'

const Help = (): JSX.Element => (
  <PageContainer
    offsetLeft="20px"
    offsetRight="20px"
    offsetBottom="20px"
    offsetTop="20px"
    offsetMode="padding"
    width="unset"
    height="unset"
    allowedRoles={['super-admin', 'admin', 'designer', 'user']}
    allowUnauthenticated={false}
    allowNotifications={true}
    showSidebar={true}>
    <TextElement
      text="Help"
      theme="h1"
      alignment="center"
      colour="black"
      display="block"
    />
    <AlignmentContainer align="center" display="block">
      <Heading id="top">Hello and welcome to Anatomiz3D Viewer!</Heading>
    </AlignmentContainer>
    <Spacer direction="vertical" amount="20px" display="block" />
    <ContentContainer>
      <Paragraph>
        Please use this section to familiarize yourself with the layout and all
        the actions that can be performed.
      </Paragraph>
      <Paragraph>
        Incase you have any additional questions that were not answered in this
        section, please feel free to reach out to your senior or write to
        <Link href="mailto:info@anatomiz3d.com">info@anatomiz3d.com</Link>
        with your query.
      </Paragraph>
      <Heading id="home">&apos;Home&apos; Page/&apos;Cases&apos; Tab</Heading>
      <Paragraph>
        Upon sign in, you will be directed to the Cases Page, which has the
        following information.
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Title</Bold> - Name given to the Case
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Status</Bold> - Open (Case under Process), Approved (Case Approved
        by client)
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Created</Bold> - Date of Case Upload
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Anatomiz3D Case ID</Bold> - Anatomiz3D reference ID you can quote
        for faster communication
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>Client Reference ID</Bold> - Any reference ID provided by Client
      </Paragraph>
      <Paragraph className="soft-return">
        <Bold>View Case</Bold> - Click on View to enter the 3D Viewer for the
        specific case.
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fviewer%2Fhome.png?alt=media&token=896c66e4-6fb0-4b04-b5a8-482df07ab80e" />
      <Heading id="profile">&apos;Profile&apos; Tab</Heading>
      <Paragraph>
        Click on the &apos;Profile&apos; Tab to request a password reset, to
        update profile information or to log out.
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fviewer%2Fprofile-button.png?alt=media&token=86a20909-b5ba-4b2b-a9f8-89dd83598576" />
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fviewer%2Fprofile.png?alt=media&token=dfdd344a-467c-4da3-b20e-03900c1675b6" />
      <Heading id="help">&apos;Help&apos; Tab</Heading>
      <Paragraph>
        Click on the &apos;Help&apos; Tab for a tutorial of the Portal
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fviewer%2Fhelp.png?alt=media&token=aa5d32a5-0dde-40e0-801c-69af8e08df84" />
      <Heading id="view-case">View Case</Heading>
      <Paragraph>
        In the case view, you will have the ability to view and manipulate the
        3D Files. Along with the viewer, you will find 3 pages on the right side
        of the page.
      </Paragraph>
      <Paragraph>
        <Bold>a&#41; Details Page</Bold> - This will have all the information
        regarding the case, any reference IDs
      </Paragraph>
      <Paragraph>
        <Bold>Approve Case</Bold> if you are satisfied with the 3D Model
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fviewer%2Fapprove-case.png?alt=media&token=42b7448f-fe1b-475d-9d35-cf1c46c062b5" />
      <Paragraph>
        <Bold>b&#41; Comments</Bold>
      </Paragraph>
      <Paragraph>
        Add any comments you would like to communicate and choose whether you
        would like to make it visible to the patient.
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fviewer%2Fcomments.png?alt=media&token=77371747-83dd-4c15-99ed-501e7ff6c3de" />
      <Paragraph>
        <Bold>c&#41; Models</Bold>
      </Paragraph>
      <Paragraph>
        You can modify the properties of the 3D Models under this tab.
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fviewer%2Fmodels.png?alt=media&token=1954967d-2389-48d4-a952-3761787052dd" />
      <Paragraph>
        <Bold>Hide/Unhide</Bold> Parts by clicking on the &apos;Eye&apos; icon.
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fviewer%2Fhide.png?alt=media&token=4d9a13ea-d3d8-4061-9d6b-0aad6f2193e6" />
      <Paragraph>
        <Bold>Edit</Bold> the colour, opacity and scale of the 3D Model by
        clicking on the <Bold>&apos;Pen&apos;</Bold> icon.
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fviewer%2Fedit.png?alt=media&token=dcfc58ab-beb0-471d-8755-bc53a8a2a2f4" />
      <Paragraph>
        <Bold>Model Colour</Bold> - Choose from 8 preset colours - Bone, Tumour,
        Artery, Vein, Implant, Screws, Skin, Soft Tissue or choose a colour of
        your preference
      </Paragraph>
      <Paragraph>
        <Bold>Scale</Bold> - Change the scale of the 3D Model (Please avoid this
        unless absolutely necessary)
      </Paragraph>
      <Paragraph>
        <Bold>Opacity</Bold> - Adjust the opacities as per need.
      </Paragraph>
      <ImageElement src="https://firebasestorage.googleapis.com/v0/b/d-viewer-b6081.appspot.com/o/tutorials%2Fviewer%2Fopacity.png?alt=media&token=56f30f70-3876-4bf9-9ba0-5d90a7034a0e" />
    </ContentContainer>
  </PageContainer>
)

export default Help
