import { getAuth, User } from 'firebase/auth'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import React, { useCallback, useEffect } from 'react'
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import NavMenu from './components/nav-menu/NavMenu'
import TopOfPage from './components/top-of-page/TopOfPage'
import Home from './pages/home/Home'
import Login from './pages/login/Login'
import NotFound from './pages/not-found/NotFound'
import CasePage from './pages/case/Case'
import Profile from './pages/profile/Profile'
import Help from './pages/help/Help'

import { getLocalStorageItem, removeLocalStorageItem } from './_data/storage'
import { useAppDispatch, useAppSelector } from './_globals/hooks'
import { RootState } from './_globals/state-store'
import { themeSlice } from './_globals/theme/theme-slice'
import { LocalUserCopy } from './_globals/user/types'
import { userSlice } from './_globals/user/user-slice'
import { getEnvironmentSettings } from './_utilities/config'
import { doesExist, getWindowHeight, getWindowWidth } from './_utilities/utils'

const themeAppSelector = (state: RootState) => state.theme

function App() {
  const dispatch = useAppDispatch()
  const themeSelector = useAppSelector(themeAppSelector)

  const handleWindowResize = useCallback(() => {
    dispatch(themeSlice.actions.setWindowWidth(getWindowWidth()))
    dispatch(themeSlice.actions.setWindowHeight(getWindowHeight()))
  }, [dispatch])

  const handleOnUserAuthChange = useCallback((user: User) => {
    if (!doesExist(user)) {
      removeLocalStorageItem('user')
      getAuth().signOut()
    }
  }, [])

  useEffect(() => {
    const appEnvironment = getEnvironmentSettings()

    // If we're in local mode, connect to the local emulator
    if (appEnvironment.ENV_CODE === 'local') {
      const firebaseFunctions = getFunctions()
      connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001)
    }

    // Check if the user has a valid session, and if so, reinitialize the user
    getLocalStorageItem('user', null).then(rawUser => {
      if (rawUser) {
        const user: LocalUserCopy = JSON.parse(rawUser)

        if (user.id !== '' && user.lastSave && user.lastSave > 0) {
          const twoWeeks = 1_209_600_000

          if (Date.now() - user.lastSave < twoWeeks) {
            dispatch(userSlice.actions.reinitializeUser(user))
          } else {
            dispatch(userSlice.actions.clearUser())
          }
        } else {
          removeLocalStorageItem('user')
          dispatch(userSlice.actions.clearUser())
        }
      }
    })

    // Setup the auth listener
    const firebaseAuth = getAuth()
    firebaseAuth.onAuthStateChanged(handleOnUserAuthChange)
  }, [dispatch, handleOnUserAuthChange])

  useEffect(() => {
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [handleWindowResize])

  return (
    <Router>
      <TopOfPage />
      <NavMenu
        hideNavMenu={themeSelector.showNavbar === false}
        pagePosition="top"
        sticky={true}>
        {themeSelector.allowNotifications ? <ToastContainer /> : null}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/case/:id" element={<CasePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/help" element={<Help />} />

          <Route path="/home" element={<Navigate to="/" />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </NavMenu>
    </Router>
  )
}

export default App
