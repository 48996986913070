/* eslint-disable camelcase */
import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import Loading from '../../components/loading/Loading'
import PageContainer from '../../components/page-container/PageContainer'
import { DatabaseCase } from '../../_types/case'
import Spacer from '../../components/spacer/Spacer'
import TextElement from '../../components/text/Text'
import { doFunctionsCall } from '../../_globals/custom-firebase/custom-firebase'
import { ApiResponse } from '../../_types/api'
import { defaultDatabaseCase } from '../../_defaults/case'
import Tabs from '../../components/tabs/Tabs'
import { DatabaseModel, Position, CameraState } from '../../_types/model'
import Button from '../../components/button/Button'
import { UserClaimRoles } from '../../_types/globals'
import { CasePageParams } from './types'
import {
  CameraControls,
  CameraPositions,
  CameraPositionsContainer,
  CameraPositionsLabel,
  CameraStateControls,
  ContentContainer,
  LoadingOverlay,
  SidebarContainer,
  StlContainer,
  StlViewerTarget,
} from './styled'
import CaseComments from './comments/CaseComments'
import ModelListing from './model-listing/ModelListing'
import { getSanitizedCameraPosition, getSnappedCameraPosition } from './helpers'
import Details from './details/Details'

let stlViewer: StlViewer = null

/**
 * Displays the case page
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <CasePage />
 * ```
 */
const CasePage = (): JSX.Element => {
  const { id } = useParams<CasePageParams>()
  const [hasFetched, setHasFetched] = useState<boolean>(false)
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)
  const [pageError, setPageError] = useState<string>('')
  const [hasCompletedFirstRender, setHasCompletedFirstRender] =
    useState<boolean>(false)
  const [hasPluginInitialized, setHasPluginInitialized] =
    useState<boolean>(false)
  const [cameraPosition, setCameraPosition] = useState<CameraState>()
  const [models, setModels] = useState<DatabaseModel[]>([])
  const [userRole, setUserRole] = useState<UserClaimRoles>()
  const [showGrid, setShowGrid] = useState<boolean>(true)
  const [caseData, setCaseData] = useState<DatabaseCase>({
    ...defaultDatabaseCase,
  })

  const canDisplayPage = useMemo(() => {
    if (
      isApiBusy ||
      hasPluginInitialized === false ||
      hasFetched === false ||
      hasCompletedFirstRender === false ||
      !userRole
    ) {
      return false
    }

    return true
  }, [
    hasCompletedFirstRender,
    hasFetched,
    hasPluginInitialized,
    isApiBusy,
    userRole,
  ])

  /**
   * Handles the initialization of the stl viewer plugin
   * @returns {void}
   */
  const handleViewerLoaded = useCallback(() => {
    try {
      stlViewer.set_bg_color('#f5f5f5')

      if (hasCompletedFirstRender === false) {
        setHasCompletedFirstRender(true)
      }
    } catch (error) {
      console.error(error)
    }
  }, [hasCompletedFirstRender])

  const handleFetchModels = useCallback(
    (fetchedCaseData: DatabaseCase) => {
      if (hasFetched === false) {
        setHasFetched(true)

        doFunctionsCall('HighestPriority', {
          signature: 'Model-GetModelsForCase',
          caseId: id,
          withModifications: true,
        })
          .then(data => {
            if (data.code === 200) {
              const fetchedModels: DatabaseModel[] = JSON.parse(data.data)
              const formattedModels = fetchedModels.map(model => ({
                ...model,
                isOpen: false,
              }))

              // Add models to the viewer plugin
              stlViewer.add_models(
                formattedModels.map(model => ({
                  id: model.modelId,
                  filename: model.filePath,
                  rotationx: model.modelRotation.x,
                  rotationy: model.modelRotation.y,
                  rotationz: model.modelRotation.z,
                  scale: model.scale,
                  x: model.modelPosition.x,
                  y: model.modelPosition.y,
                  z: model.modelPosition.z,
                  color: model.colour,
                })),
              )

              // Set the camera position
              stlViewer.set_grid(fetchedCaseData.isGridVisible ?? true)
              stlViewer.set_camera_state(
                getSanitizedCameraPosition(fetchedCaseData.sceneCameraPosition),
              )

              setCameraPosition(
                getSanitizedCameraPosition(fetchedCaseData.sceneCameraPosition),
              )

              setTimeout(() => {
                stlViewer.set_grid(fetchedCaseData.isGridVisible ?? true)
                stlViewer.set_camera_state(
                  getSanitizedCameraPosition(
                    fetchedCaseData.sceneCameraPosition,
                  ),
                )
              }, 1000)

              if (formattedModels.length === 0) {
                setHasCompletedFirstRender(true)
              }

              setModels(formattedModels)
              setIsApiBusy(false)
            } else if (data.code === 500) {
              console.error(data)
              setPageError('An error occurred while fetching models')
            }
          })
          .catch((error: Error) => {
            console.error(error)
            setPageError('An error occurred while fetching models')
          })
      }
    },
    [hasFetched, id],
  )

  const handlePageReady = useCallback(() => {
    doFunctionsCall('HighestPriority', {
      signature: 'Case-Get',
      caseId: id,
    })
      .then((response: ApiResponse) => {
        if (response.code === 200) {
          const fetchedCase = JSON.parse(response.data) as DatabaseCase
          setCaseData(fetchedCase)

          if (!hasPluginInitialized) {
            setHasPluginInitialized(true)
            setShowGrid(fetchedCase.isGridVisible ?? true)

            // ensure the plugin is disposed before potentially re-initializing
            try {
              stlViewer.dispose()
            } catch {
              // eslint-disable-next-line no-restricted-syntax
              console.log('No viewer to dispose. Continuing...')
            }

            stlViewer = new StlViewer(
              document.getElementById('stl-viewer-target'),
              {
                auto_resize: true,
                canvas_height: '100%',
                canvas_width: '100%',
                allow_drag_and_drop: false,
                controls: 0,
                ready_callback: () => handleFetchModels(fetchedCase),
                all_loaded_callback: () => handleViewerLoaded(),
              },
            )
          }
        } else if (response.code === 500) {
          console.error(response)
          setPageError('An error occurred while fetching case data')
        }
      })
      .catch((error: Error) => {
        console.error(error)
        setPageError('An error occurred while fetching case data')
      })

    doFunctionsCall('HighestPriority', {
      signature: 'User-GetRole',
      caseId: id,
    }).then((response: ApiResponse) => {
      setUserRole((response.data as UserClaimRoles) ?? 'guest')
    })
  }, [handleFetchModels, handleViewerLoaded, hasPluginInitialized, id])

  const handleDataChange = useCallback(
    (modelId: string, attribute: string, newValue: unknown) => {
      const foundModel = models.find(model => model.id === modelId)

      const updatedModel = {
        ...foundModel,
        [attribute]: newValue,
      }

      setModels(previous => {
        const newState = [...previous]
        const foundIndex = newState.findIndex(model => model.id === modelId)

        newState[foundIndex] = updatedModel

        return newState
      })

      if (attribute === 'colour') {
        stlViewer.set_color(foundModel.modelId, newValue as string)
      } else if (attribute === 'modelPosition') {
        const newPosition = newValue as Position

        stlViewer.set_position(
          foundModel.modelId,
          newPosition.x,
          newPosition.y,
          newPosition.z,
        )
      } else if (attribute === 'scale') {
        const newScale = newValue as number

        stlViewer.set_scale(foundModel.modelId, newScale, newScale, newScale)
      } else if (attribute === 'opacity') {
        stlViewer.set_opacity(foundModel.modelId, newValue as number)
      }
    },
    [models],
  )

  const snapToCameraPosition = useCallback(
    (position: string) => {
      if (hasCompletedFirstRender === false) {
        return null
      }

      const newCameraPosition = getSnappedCameraPosition(position)

      stlViewer.set_camera_state(newCameraPosition)
    },
    [hasCompletedFirstRender],
  )

  const handleResetCameraPosition = useCallback(() => {
    if (hasCompletedFirstRender === true) {
      stlViewer.set_camera_state(cameraPosition)
    }
  }, [cameraPosition, hasCompletedFirstRender])

  /**
   * Handles the preview of a model when its attributes are changed
   *
   * @param {number} modelId The ID of the model to preview
   * @param {string} attribute The attribute to preview
   * @param {string | Position} value The value of the attribute to preview
   * @returns {void}
   */
  const handleModelPreview = useCallback(
    (modelId: number, attribute: string, value: string | Position) => {
      if (attribute === 'colour') {
        stlViewer.set_color(modelId, value as string)
      } else if (attribute === 'modelRotation') {
        const newRotation = value as Position

        stlViewer.rotate(modelId, newRotation.x, newRotation.y, newRotation.z)
      }
    },
    [],
  )

  const handleToggleGrid = useCallback(() => {
    if (hasCompletedFirstRender === true) {
      const newShowGrid = !showGrid

      setShowGrid(newShowGrid)
      stlViewer.set_grid(newShowGrid)
    }
  }, [hasCompletedFirstRender, showGrid])

  return (
    <PageContainer
      offsetLeft="20px"
      offsetRight="20px"
      offsetBottom="20px"
      offsetMode="padding"
      width="unset"
      height="unset"
      pageError={pageError}
      allowUnauthenticated={true}
      allowNotifications={true}
      showSidebar={true}
      onPageReady={() => handlePageReady()}>
      {canDisplayPage === false ? (
        <LoadingOverlay>
          <Loading type="large" />
        </LoadingOverlay>
      ) : null}
      <Spacer direction="vertical" amount="20px" display="block" />
      <TextElement
        text={caseData.title ?? 'Loading...'}
        theme="h1"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="20px" display="block" />
      <ContentContainer>
        <StlContainer>
          <StlViewerTarget id="stl-viewer-target" />
          <CameraControls>
            <CameraStateControls>
              <Button
                text="Toggle Grid"
                theme="secondary"
                callback={() => handleToggleGrid()}
                display="inline-block"
              />
              <Button
                text="Reset View"
                theme="secondary"
                callback={() => handleResetCameraPosition()}
                display="inline-block"
              />
            </CameraStateControls>
            <CameraPositionsContainer>
              <CameraPositionsLabel>Snap To View</CameraPositionsLabel>
              <CameraPositions>
                <Button
                  text="Front"
                  theme="secondary"
                  callback={() => snapToCameraPosition('front')}
                  display="inline-block"
                  size="small"
                />
                <Button
                  text="Back"
                  theme="secondary"
                  callback={() => snapToCameraPosition('back')}
                  display="inline-block"
                  size="small"
                />
                <Button
                  text="Left"
                  theme="secondary"
                  callback={() => snapToCameraPosition('left')}
                  display="inline-block"
                  size="small"
                />
                <Button
                  text="Right"
                  theme="secondary"
                  callback={() => snapToCameraPosition('right')}
                  display="inline-block"
                  size="small"
                />
                <Button
                  text="Top"
                  theme="secondary"
                  callback={() => snapToCameraPosition('top')}
                  display="inline-block"
                  size="small"
                />
                <Button
                  text="Bottom"
                  theme="secondary"
                  callback={() => snapToCameraPosition('bottom')}
                  display="inline-block"
                  size="small"
                />
              </CameraPositions>
            </CameraPositionsContainer>
          </CameraControls>
        </StlContainer>
        <SidebarContainer>
          {isApiBusy ? (
            <Loading type="large" />
          ) : (
            <Tabs
              structure={[
                {
                  id: 'models',
                  title: 'Models',
                  content: (
                    <ModelListing
                      models={models}
                      onDataChange={(modelId, attribute, newValue) =>
                        handleDataChange(modelId, attribute, newValue)
                      }
                      onModelPreview={(modelId, attribute, value) =>
                        handleModelPreview(modelId, attribute, value)
                      }
                    />
                  ),
                },
                {
                  id: 'details',
                  title: 'Details',
                  content: (
                    <Details
                      caseData={caseData}
                      userRole={userRole as UserClaimRoles}
                    />
                  ),
                },
                {
                  id: 'comments',
                  title: 'Comments',
                  content: (
                    <CaseComments
                      caseData={caseData}
                      userRole={userRole as UserClaimRoles}
                    />
                  ),
                },
              ]}
            />
          )}
        </SidebarContainer>
      </ContentContainer>
    </PageContainer>
  )
}

export default CasePage
