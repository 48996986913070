import XDate from 'xdate'

import { CaseStatus } from '../../../_types/globals'

/**
 * Format a timestamp into a human-readable date
 *
 * @param {number} timestamp - The timestamp to format
 * @returns {string} - The formatted date
 *
 * @example
 * ```ts
 * const formattedDate = formatCreatedAtTimestamp(123456789)
 * ```
 */
const formatCreatedAtTimestamp = (timestamp: number) => {
  const formattedDate = new XDate(timestamp).toString('dddd, MMMM dS, yyyy')

  return formattedDate
}

/**
 * Format a case status into a human-readable string
 *
 * @param {CaseStatus} caseStatus - The case status to format
 * @returns {string} - The formatted case status
 *
 * @example
 * ```ts
 * const formattedStatus = formatStatus('approved')
 * ```
 */
const formatStatus = (caseStatus: CaseStatus) => {
  if (caseStatus === 'approved') {
    return 'Approved'
  }
  if (caseStatus === 'completed') {
    return 'Completed'
  }
  if (caseStatus === 'open') {
    return 'Open'
  }

  return 'Unknown'
}

export { formatCreatedAtTimestamp, formatStatus }
