import styled from 'styled-components'

const Section = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: auto;
  display: ${props => props.theme.display};
  flex-wrap: wrap;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`
Section.defaultProps = {
  theme: {
    display: 'block',
    maxWidth: '1000px',
  },
}

const StlContainer = styled.div`
  flex: 1;
`

const StlViewerTarget = styled.div`
  height: calc(100% - 50px);
  width: 100%;
  border: 1px solid black;

  & canvas {
    width: 100% !important;
    height: 100% !important;
  }
`

const FileUploadTarget = styled.input`
  display: none;
`

const CameraControls = styled.div`
  text-align: center;
  padding: 10px 10px 0 10px;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
`

const CameraStateControls = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;
  flex-wrap: wrap;
  align-content: center;

  @media (max-width: 800px) {
    flex-wrap: nowrap;
    justify-content: center;
  }
`

const CameraPositionsContainer = styled.div`
  display: block;
`

const CameraPositions = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    justify-content: center;
  }
`

const CameraPositionsLabel = styled.label`
  width: 100%;
  flex: 1;
  font-size: 90%;
`

const SidebarContainer = styled.div`
  max-width: 400px;
  width: 100%;
`

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 10;

  & div {
    height: 100%;
  }
`

const ContentContainer = styled.div`
  display: flex;
  height: calc(100vh - 200px);
  max-width: 1500px;
  margin: auto;

  @media (max-width: 850px) {
    & {
      flex-wrap: wrap;
      height: auto;
    }

    & ${StlContainer}, & ${SidebarContainer} {
      flex: 1;
      min-width: 100%;
    }

    & ${StlContainer} {
      min-height: 600px;
      margin-bottom: 50px;
    }
  }
`

export {
  ContentContainer,
  Section,
  StlContainer,
  StlViewerTarget,
  FileUploadTarget,
  CameraControls,
  CameraStateControls,
  CameraPositionsContainer,
  CameraPositions,
  CameraPositionsLabel,
  SidebarContainer,
  LoadingOverlay,
}
