import styled, { keyframes } from 'styled-components'

import { colours, fontSize } from '../../_globals/theme'

const backgroundGradient = keyframes`
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`

const RestyledButton = styled.button`
  cursor: ${props => props.theme.cursor};
  opacity: ${props => props.theme.opacity};
  transition: opacity 0.3s, border-radius 0.3s;
  padding-top: ${props => props.theme.paddingTop};
  padding-left: ${props => props.theme.paddingLeft};
  padding-right: ${props => props.theme.paddingRight};
  padding-bottom: ${props => props.theme.paddingBottom};
  color: ${props => props.theme.colour};
  background-color: ${props => props.theme.background};
  font-size: ${props => props.theme.fontSize};
  border-radius: 5px;
  border: none;
  box-shadow: 1px 1px 4px #6f6f6f;
  text-align: center;

  &:hover {
    opacity: ${props => (props.theme.isVisible ? 1 : 0.5)};
    border-radius: ${props => (props.theme.isVisible ? '50px' : '10px')};
  }
`
RestyledButton.defaultProps = {
  theme: {
    background: colours.highlight,
    colour: 'white',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    fontSize: fontSize.regular,
    opacity: 0.8,
    cursor: 'pointer',
  },
}

const FlairBackground = styled.button`
  cursor: ${props => props.theme.cursor};
  opacity: ${props => props.theme.opacity};
  transition: opacity 0.3s, border-radius 0.3s;
  color: ${props => props.theme.colour};
  padding: 5px;
  background-color: ${props => props.theme.background};
  background: ${props => `linear-gradient(${props.theme.background})`};
  font-size: ${props => props.theme.fontSize};
  border-radius: 50px;
  border: none;
  text-align: center;
  background-size: 200% 100%;
  animation-name: ${backgroundGradient};
  animation-duration: 10s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;

  &:hover,
  &:hover > div {
    opacity: ${props => (props.theme.isVisible ? 1 : 0.5)};
    border-radius: ${props => (props.theme.isVisible ? '10px' : '50px')};
  }
`
FlairBackground.defaultProps = {
  theme: {
    background: colours.highlight,
    colour: 'white',
    fontSize: fontSize.regular,
    opacity: 0.8,
    cursor: 'pointer',
  },
}

const FlairButton = styled.div`
  transition: opacity 0.3s, border-radius 0.3s;
  background-color: black;
  padding: 5px 15px;
  border-radius: 50px;
`

export { RestyledButton, FlairBackground, FlairButton }
