import React, { useCallback, useContext, useEffect, useState } from 'react'

import Chat from '../../../components/chat/Chat'
import { doFunctionsCall } from '../../../_globals/custom-firebase/custom-firebase'
import { useAppSelector } from '../../../_globals/hooks'
import { NotificationContext } from '../../../_globals/notifications/notification-context'
import { RootState } from '../../../_globals/state-store'
import { DatabaseComment } from '../../../_types/comment'
import { generateId } from '../../../_utilities/utils'
import { Section } from '../styled'
import { CaseCommentsProps } from './types'

const userAppSelector = (state: RootState) => state.user

/**
 * Displays the comments for a case
 *
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <CaseComments caseData={caseData} />
 * ```
 */
const CaseComments = ({ caseData, userRole = 'guest' }: CaseCommentsProps) => {
  const { showNotification } = useContext(NotificationContext)
  const userSelector = useAppSelector(userAppSelector)
  const [lastFetch, setLastFetch] = useState<number>(-1)
  const [comments, setComments] = useState<DatabaseComment[]>([])
  const [hasInitiallyFetchedComments, setHasInitiallyFetchedComments] =
    useState<boolean>(false)

  const handleAddComment = useCallback(
    (comment: string) => {
      if (userRole === 'guest') {
        showNotification({
          title: 'You are not allowed to comment',
          type: 'error',
          dismissAfter: 3000,
        })

        return null
      }

      setComments(previous => [
        ...previous,
        {
          authorId: userSelector.id,
          caseId: caseData.id,
          comment,
          createdTimestamp: Date.now(),
          id: `temp-${generateId(20)}`,
          photoIds: [],
          visibleToPatient: false,
          replyToCommentId: '',
          userDisplayName: userSelector.displayName,
        } as DatabaseComment,
      ])
    },
    [
      caseData?.id,
      showNotification,
      userRole,
      userSelector?.displayName,
      userSelector?.id,
    ],
  )

  const handleRefreshRequest = useCallback(() => {
    if (!caseData || !caseData.id || caseData.id === '') {
      return null
    }

    const since = hasInitiallyFetchedComments ? lastFetch : null

    doFunctionsCall('HighestPriority', {
      signature: 'Comment-GetCommentsForCase',
      caseId: caseData.id,
      since,
    })
      .then(data => {
        if (data.code === 200) {
          const fetchedComments: DatabaseComment[] = JSON.parse(data.data)

          if (hasInitiallyFetchedComments === false) {
            // load all comments
            setComments(() => [...fetchedComments])
          } else {
            // load only new comments
            setComments(previous => [
              ...previous.filter(
                previousMessage =>
                  !fetchedComments.some(
                    // eslint-disable-next-line max-nested-callbacks
                    existingMessage =>
                      existingMessage.authorId === previousMessage.authorId &&
                      existingMessage.comment === previousMessage.comment,
                  ),
              ),
              ...fetchedComments,
            ])
          }

          if (fetchedComments.length > 0) {
            // set the last fetch to the last comment's timestamp
            setLastFetch(
              () =>
                fetchedComments[fetchedComments.length - 1].createdTimestamp,
            )
          }

          setHasInitiallyFetchedComments(true)
        } else {
          console.error(data)

          showNotification({
            title: 'Failed to fetch comments.',
            type: 'error',
            dismissAfter: 3000,
          })
        }
      })
      .catch(error => {
        console.error(error)

        showNotification({
          title: 'Failed to fetch comments',
          type: 'error',
          dismissAfter: 3000,
        })
      })
  }, [caseData, hasInitiallyFetchedComments, lastFetch, showNotification])

  useEffect(() => {
    if (hasInitiallyFetchedComments === false) {
      handleRefreshRequest()
    }
  }, [handleRefreshRequest, hasInitiallyFetchedComments])

  return (
    <Section>
      <Chat
        comments={comments}
        caseId={caseData.id}
        onAddComment={comment => handleAddComment(comment)}
        onRefreshRequest={() => handleRefreshRequest()}
        userRole={userRole}
        allowChat={[
          'super-admin',
          'admin',
          'designer',
          'client',
          'patient',
        ].includes(userRole)}
      />
    </Section>
  )
}

export default CaseComments
