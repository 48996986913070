import styled from 'styled-components'
import { colours, fontSize } from '../../_globals/theme'

const ChatContainer = styled.div``

const CommentContainer = styled.div`
  max-height: ${props => props.theme.maxHeight};
  height: 100vh;
  overflow-y: auto;
`
CommentContainer.defaultProps = {
  theme: {
    maxHeight: 'calc(100vh - 250px)',
  },
}

const BottomControls = styled.div`
  display: flex;
  vertical-align: middle;
  max-width: 800px;
  margin: auto;

  & input,
  & textarea {
    flex: 1;
    vertical-align: middle;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: 0;
    height: 60px;
    width: 100%;
  }
`

const VisibleToPatientContainer = styled.div`
  padding: 5px 10px;
  vertical-align: middle;
  text-align: center;
  border: none;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  height: 60px;
`

const SendComment = styled.button`
  padding: 5px 10px;
  background: none;
  border-radius: 0;
  vertical-align: middle;
  border: none;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  cursor: pointer;
  height: 60px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: ${fontSize.regular};
`

const MessageInput = styled.textarea`
  border: 1px solid black;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 5px 10px;
`

const Author = styled.p`
  position: absolute;
  top: -24px;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.colour};
  padding: 3px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`
Author.defaultProps = {
  theme: {
    background: 'white',
    colour: 'black',
  },
}

const CommentTimestamp = styled.p`
  font-size: 80%;
  opacity: 0.5;
  margin-top: 10px;
  display: none;
`

const Message = styled.div`
  position: relative;
  padding: 10px 20px;
  background-color: #efefef;
  margin: 20px;
  border-radius: 20px;
  min-width: 300px;

  &:focus ${CommentTimestamp} {
    display: block;
  }

  &:not(.mine) {
    border-bottom-left-radius: 0;
    margin-top: 50px;
    border-top: 1px solid ${props => props.theme.background};
    border-right: 1px solid ${props => props.theme.background};
  }
  &.mine {
    background-color: ${colours.highlight};
    color: white;
    border-bottom-right-radius: 0;

    & ${Author} {
      display: none;
    }
    & ${CommentTimestamp} {
      color: white;
      opacity: 1;
    }
  }

  @media screen and (max-width: 700px) {
    & {
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
  }
`
Message.defaultProps = {
  theme: {
    background: 'white',
  },
}

export {
  ChatContainer,
  BottomControls,
  CommentContainer,
  SendComment,
  VisibleToPatientContainer,
  Message,
  Author,
  CommentTimestamp,
  MessageInput,
}
