import { FormError } from '../../_types/globals'
import { DatabaseUser } from '../../_types/user'

/**
 * Validate a user object
 *
 * @param {DatabaseUser} user - The user object to validate
 * @returns {FormError[]} - An array of errors
 *
 * @example
 * ```ts
 * const errors = validate(user)
 * ```
 */
const validate = (user: DatabaseUser) => {
  const errors: FormError[] = []

  if (user.displayName === '') {
    errors.push({
      message: 'Display Name cannot be blank!',
      elementId: 'displayName',
    })
  }

  if (user.email === '') {
    errors.push({
      message: 'Email cannot be blank!',
      elementId: 'email',
    })
  }

  return errors
}

export { validate }
