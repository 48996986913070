import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'

import AlignmentContainer from '../../components/alignment-container/AlignmentContainer'
import Button from '../../components/button/Button'
import PageContainer from '../../components/page-container/PageContainer'
import Spacer from '../../components/spacer/Spacer'
import TextElement from '../../components/text/Text'
import { colours } from '../../_globals/theme'
import { ContentContainer } from './styled'

/**
 * Not Found (404) page
 *
 * @returns {JSX.Element}
 *
 * ```tsx
 * <NotFound />
 * ```
 */
const NotFound = (): JSX.Element => {
  const navigation = useNavigate()

  const handleHomeClick = useCallback(() => {
    navigation('/')
  }, [navigation])

  return (
    <PageContainer
      width="100%"
      height="100%"
      allowUnauthenticated={true}
      showSidebar={false}
      backgroundColour={colours.background}>
      <ContentContainer>
        <TextElement
          text="Nothing to see here"
          theme="h1"
          alignment="center"
          display="block"
          sizeOverride="5rem"
        />
        <Spacer direction="vertical" amount="20px" display="block" />
        <TextElement
          text="The page you were looking for could not be found, sorry!"
          theme="paragraph"
          alignment="center"
          display="block"
        />
        <Spacer direction="vertical" amount="40px" display="block" />
        <AlignmentContainer align="center" display="block">
          <Button
            text="Let's get you back home"
            theme="flair"
            callback={() => handleHomeClick()}
            display="block"
          />
        </AlignmentContainer>
      </ContentContainer>
    </PageContainer>
  )
}

export default NotFound
