import React, { useEffect, useMemo, useState } from 'react'
import { DefaultTheme } from 'styled-components'

import { useAppSelector } from '../../_globals/hooks'
import { RootState } from '../../_globals/state-store'
import NavBar from './components/nav-bar/NavBar'
import { ContentContainer, NavMenuAndPageContainer } from './styled'
import { NavMenuProps } from './types'

const themeAppSelector = (state: RootState) => state.theme

/**
 * A menu for navigation
 *
 * @param {NavMenuProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <NavMenu
 *  hideNavMenu={themeSelector.showNavbar === false}
 *  pagePosition="top"
 *  sticky={true}
 * >
 *  <p>Am I menu content? Yes, yes I believe I am.</p>
 * </NavMenu>
 * ```
 */
const NavMenu = ({
  pagePosition,
  sticky,
  children,
  hideCartButton,
  hideNavMenu,
}: NavMenuProps): JSX.Element => {
  const themeSelector = useAppSelector(themeAppSelector)
  const [pageHeight, setPageHeight] = useState<string>('')

  const contentTheme = useMemo(() => {
    const theme: DefaultTheme = themeSelector.showNavbar
      ? {
          height: pageHeight === '' ? '100vh' : pageHeight,
          justify: sticky ? 'height' : 'min-height',
        }
      : null

    return theme
  }, [pageHeight, sticky, themeSelector?.showNavbar])

  useEffect(() => {
    let navBarHeight = 90

    if (themeSelector.isPhoneView) {
      navBarHeight = hideCartButton === true ? 110 : 160
    }

    navBarHeight += 2

    const useableHeight = Math.round(
      themeSelector.windowHeight - navBarHeight,
    ).toString()

    setPageHeight(`${useableHeight}px`)
  }, [hideCartButton, themeSelector.isPhoneView, themeSelector.windowHeight])

  return (
    <NavMenuAndPageContainer>
      {pagePosition === 'top' && hideNavMenu === false ? (
        <NavBar pagePosition="top" hideCartButton={hideCartButton || false} />
      ) : null}
      <ContentContainer theme={contentTheme}>{children}</ContentContainer>
      {pagePosition === 'bottom' && hideNavMenu === false ? (
        <NavBar
          pagePosition="bottom"
          hideCartButton={hideCartButton || false}
        />
      ) : null}
    </NavMenuAndPageContainer>
  )
}

export default NavMenu
