import React, { useCallback, useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import ReactSlider from 'react-slider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons'

import Spacer from '../../../components/spacer/Spacer'
import TextElement from '../../../components/text/Text'
import { Position } from '../../../_types/model'
import {
  HeaderIcon,
  Label,
  LabelContainer,
  LabelInput,
  ModelListingContainer,
  ModelListingHeader,
  ModelSettings,
} from './styled'
import { ModelListingProps, RotationControl } from './types'
import { insertTooltips, saveModelModifications } from './helpers'

/**
 * Displays the model listing for a case
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <ModelListing models={models} onDataChange={onDataChange} onModelPreview={onModelPreview} />
 * ```
 */
const ModelListing = ({
  models,
  onDataChange,
  onModelPreview,
}: ModelListingProps): JSX.Element => {
  const [rotations, setRotations] = useState<RotationControl>({})

  const handleModelPreview = useCallback(
    (
      stlModelId: number,
      modelId: string,
      attribute: string,
      value: string | Position,
    ) => {
      onModelPreview(stlModelId, attribute, value)
      saveModelModifications(modelId, value as string, models)
    },
    [models, onModelPreview],
  )

  const handleDataChange = useCallback(
    (id: string, attribute: string, newValue: unknown) => {
      if (attribute === 'colour') {
        saveModelModifications(id, newValue as string, models)
      }

      if (attribute === 'modelRotation') {
        const modelId = models.find(model => model.id === id)?.modelId ?? 0

        handleModelPreview(modelId, id, attribute, newValue as Position)
        onDataChange(id, attribute, rotations[id])
      } else {
        onDataChange(id, attribute, newValue)
      }
    },
    [handleModelPreview, models, onDataChange, rotations],
  )

  const handleToggleModelVisibility = useCallback(
    (id: string) => {
      const model = models.find(modelData => modelData.id === id)

      handleDataChange(id, 'opacity', model?.opacity === 0 ? 1 : 0)
    },
    [handleDataChange, models],
  )

  useEffect(() => {
    models.forEach(model => {
      if (!rotations[model.id]) {
        setRotations(previous => ({
          ...previous,
          [model.id]: {
            x: model.modelRotation.x,
            y: model.modelRotation.y,
            z: model.modelRotation.z,
          },
        }))
      }
    })

    insertTooltips()
  }, [models, rotations])

  return (
    <ModelListingContainer>
      {models.map(model => (
        <ModelSettings key={model.id} id={`model-${model.modelId}`}>
          <ModelListingHeader>
            <TextElement
              text={model.fileName}
              theme="h3"
              alignment="left"
              display="block"
            />
            <HeaderIcon onClick={() => handleToggleModelVisibility(model.id)}>
              <FontAwesomeIcon
                icon={model.opacity === 0 ? faEye : faEyeSlash}
                color="#000000"
                size="1x"
              />
            </HeaderIcon>
            <HeaderIcon
              onClick={() =>
                handleDataChange(model.id, 'isOpen', !model.isOpen)
              }>
              <FontAwesomeIcon icon={faPencilAlt} color="#000000" size="1x" />
            </HeaderIcon>
          </ModelListingHeader>
          {model.isOpen ? (
            <>
              <Spacer direction="vertical" amount="20px" display="block" />
              <Label>Model Colour</Label>
              <SketchPicker
                color={model.colour}
                disableAlpha={true}
                presetColors={[
                  'rgb(239, 225, 206)',
                  'rgb(192, 18, 18)',
                  'rgb(126, 0, 0)',
                  'rgb(63, 39, 131)',
                  'rgb(103, 101, 106)',
                  'rgb(240, 136, 233)',
                  'rgb(250, 216, 204)',
                  'rgb(117, 83, 74)',
                ]}
                onChange={value => {
                  handleModelPreview(
                    model.modelId,
                    model.id,
                    'colour',
                    value.hex,
                  )
                }}
                onChangeComplete={value => {
                  handleDataChange(model.id, 'colour', value.hex)
                }}
              />
              <Spacer direction="vertical" amount="20px" display="block" />
              <LabelContainer>
                <Label>Scale</Label>
                <LabelInput
                  type="number"
                  value={(model.scale * 100).toFixed(0)}
                  onChange={textEvent => {
                    const newScale = Number.isNaN(textEvent.target.value)
                      ? 0
                      : Number.parseFloat(textEvent.target.value) / 100

                    handleDataChange(model.id, 'scale', newScale)
                  }}
                />
                %
              </LabelContainer>
              <ReactSlider
                className="default-slider"
                min={-1}
                max={2}
                step={0.01}
                value={model.scale}
                onChange={value => {
                  handleDataChange(model.id, 'scale', value)
                }}
              />
              <Spacer direction="vertical" amount="10px" display="block" />
              <LabelContainer>
                <Label>Opacity</Label>
                <LabelInput
                  type="number"
                  value={(model.opacity * 100).toFixed(0)}
                  onChange={textEvent => {
                    const newOpacity = Number.isNaN(textEvent.target.value)
                      ? 0
                      : Number.parseFloat(textEvent.target.value) / 100

                    handleDataChange(model.id, 'opacity', newOpacity)
                  }}
                />
                %
              </LabelContainer>
              <ReactSlider
                className="default-slider"
                min={0}
                max={1}
                step={0.1}
                value={model.opacity}
                onChange={value => {
                  handleDataChange(model.id, 'opacity', value)
                }}
              />
            </>
          ) : null}
        </ModelSettings>
      ))}
      <Spacer direction="vertical" display="block" amount="20px" />
    </ModelListingContainer>
  )
}

export default ModelListing
