import styled from 'styled-components'

const Container = styled.section`
  display: block;
  position: relative;
  width: ${props => props.theme.width};
  height: ${props => props.theme.height};
  max-width: ${props => props.theme.width};
  padding-left: ${props => props.theme.paddingLeft};
  padding-top: ${props => props.theme.paddingTop};
  padding-right: ${props => props.theme.paddingRight};
  padding-bottom: ${props => props.theme.paddingBottom};
  margin-left: ${props => props.theme.marginLeft};
  margin-top: ${props => props.theme.marginTop};
  margin-right: ${props => props.theme.marginRight};
  margin-bottom: ${props => props.theme.marginBottom};
  background-color: ${props => props.theme.background};
`
Container.defaultProps = {
  theme: {
    width: '100%',
    height: '100vh',
    paddingLeft: '0px',
    paddingTop: '0px',
    paddingRight: '0px',
    paddingBottom: '0px',
    marginLeft: '0px',
    marginTop: '0px',
    marginRight: '0px',
    marginBottom: '0px',
    background: '#ffffff',
  },
}

const AlertContainer = styled.div`
  max-width: 80%;
  margin: 20px auto;
`

const Logo = styled.img`
  display: block;
  margin: auto;
  width: 100%;
  max-width: 800px;
`

export { Container, AlertContainer, Logo }
