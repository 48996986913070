import React, { useCallback, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useNavigate } from 'react-router'

import Spacer from '../../../../../spacer/Spacer'
import TextElement from '../../../../../text/Text'
import { Link } from './styled'
import { NavLinkProps } from './types'

/**
 * Navigation Links within the NavBar
 * @see NavBar
 *
 * @param {NavLinkProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 *  <NavLink icon={faUserCircle} title="Profile" url="/profile" />
 * ```
 */
const NavLink = ({
  icon,
  title,
  url,
  activeUrls,
  onClick,
}: NavLinkProps): JSX.Element => {
  const { pathname } = useLocation()
  const navigation = useNavigate()

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(url)
    } else {
      navigation(url)
    }
  }, [navigation, onClick, url])

  const isActive = useMemo(
    () => activeUrls.includes(pathname),
    [activeUrls, pathname],
  )

  return (
    <Link onClick={() => handleClick()} className={isActive ? 'active' : ''}>
      <FontAwesomeIcon icon={icon} size="2x" color="#000000" />
      <Spacer direction="horizontal" amount="5px" display="inline-block" />
      <TextElement text={title} colour="black" theme="paragraph" />
    </Link>
  )
}

export default NavLink
