import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import React, { useCallback, useContext, useState } from 'react'

import AlignmentContainer from '../../components/alignment-container/AlignmentContainer'
import Button from '../../components/button/Button'
import Loading from '../../components/loading/Loading'
import PageContainer from '../../components/page-container/PageContainer'
import PrettyFormContainer from '../../components/pretty-form-container/PrettyFormContainer'
import Spacer from '../../components/spacer/Spacer'
import TextInput from '../../components/text-input/TextInput'
import TextElement from '../../components/text/Text'
import { removeLocalStorageItem } from '../../_data/storage'
import { doFunctionsCall } from '../../_globals/custom-firebase/custom-firebase'
import { useAppSelector } from '../../_globals/hooks'
import { NotificationContext } from '../../_globals/notifications/notification-context'
import { RootState } from '../../_globals/state-store'
import { DatabaseUser } from '../../_types/user'
import { validate } from './helpers'

const userAppSelector = (state: RootState) => state.user

/**
 * Displays the profile page
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <Profile />
 * ```
 */
const Profile = (): JSX.Element => {
  const { showNotification } = useContext(NotificationContext)
  const userSelector = useAppSelector(userAppSelector)
  const [user, setUser] = useState<DatabaseUser>()
  const [criticalError, setCriticalError] = useState<string>('')
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)

  const handleFormDataChange = useCallback(
    (attribute: string, newValue: unknown) => {
      setUser(previous => ({ ...previous, [attribute]: newValue }))
    },
    [],
  )

  const handlePageReady = useCallback(() => {
    doFunctionsCall('HighestPriority', {
      signature: 'User-Get',
      userId: userSelector.id,
    })
      .then(data => {
        if (data.code === 200) {
          const fetchedUser: DatabaseUser = JSON.parse(data.data)
          setUser(fetchedUser)
        } else if (data.code === 500) {
          console.error(data)
          setCriticalError('Could not fetch user')

          showNotification({
            title: 'Failed to fetch user',
            type: 'error',
            dismissAfter: 3000,
          })
        }
      })
      .catch((error: Error) => {
        console.error(error)
        setCriticalError('Could not fetch user')

        showNotification({
          title: 'Failed to fetch user',
          type: 'error',
          dismissAfter: 3000,
        })
      })
  }, [showNotification, userSelector.id])

  const handleSaveClick = useCallback(() => {
    if (isApiBusy === true) {
      showNotification({
        title: 'Working on it!',
        type: 'info',
        dismissAfter: 3000,
      })

      return null
    }

    const formErrors = validate(user)
    if (formErrors.length > 0) {
      formErrors.forEach(error => {
        showNotification({
          title: error.message,
          type: 'error',
          dismissAfter: 3000,
        })
      })

      return null
    }

    setIsApiBusy(true)

    doFunctionsCall('Admin', {
      signature: 'User-Update',
      id: user.id,
      displayName: user.displayName,
      email: user.email,
    })
      .then(data => {
        if (data.code === 200) {
          if (user.email !== userSelector.email) {
            doFunctionsCall('HighestPriority', {
              signature: 'User-ChangeEmail',
              email: user.email,
            })
              .then(() => {
                showNotification({
                  title: 'Successfully updated user info!',
                  type: 'success',
                  dismissAfter: 3500,
                })

                setTimeout(() => {
                  removeLocalStorageItem('user')
                  getAuth().signOut()
                  window.location.reload()
                }, 1000)
              })
              .catch((error: Error) => {
                console.error(error)
                showNotification({
                  title: error.message,
                  type: 'error',
                  dismissAfter: 5000,
                })
              })
          } else {
            showNotification({
              title: 'Successfully updated user info!',
              type: 'success',
              dismissAfter: 3500,
            })
          }
        } else if (data.code === 500) {
          console.error(data)
          setCriticalError('Could not update user')

          showNotification({
            title: data.message,
            type: 'error',
            dismissAfter: 5000,
          })
        }
      })
      .catch((error: Error) => {
        console.error(error)
        setCriticalError('Could not update user')

        showNotification({
          title: error.message,
          type: 'error',
          dismissAfter: 5000,
        })
      })
      .finally(() => {
        setIsApiBusy(false)
      })
  }, [isApiBusy, user, showNotification, userSelector.email])

  const handlePasswordResetClick = useCallback(() => {
    if (isApiBusy === true) {
      showNotification({
        title: 'Working on it!',
        type: 'info',
        dismissAfter: 3000,
      })

      return null
    }

    setIsApiBusy(true)

    sendPasswordResetEmail(getAuth(), userSelector.email)
      .then(() => {
        showNotification({
          title: 'Password reset email sent!',
          type: 'success',
          dismissAfter: 3000,
        })
      })
      .catch((error: Error) => {
        showNotification({
          title: error.message,
          type: 'error',
          dismissAfter: 3000,
        })
      })
      .finally(() => {
        setIsApiBusy(false)
      })
  }, [isApiBusy, showNotification, userSelector.email])

  const handleLogoutClick = useCallback(() => {
    removeLocalStorageItem('user')
    getAuth().signOut()
    window.open(`${window.location.origin}/login`, '_self')
  }, [])

  return (
    <PageContainer
      offsetLeft="20px"
      offsetRight="20px"
      offsetBottom="20px"
      offsetTop="20px"
      offsetMode="padding"
      width="unset"
      height="unset"
      allowedRoles={['super-admin', 'admin', 'designer', 'user']}
      allowUnauthenticated={false}
      pageError={criticalError}
      allowNotifications={true}
      showSidebar={true}
      onPageReady={() => handlePageReady()}>
      <TextElement
        text="Profile"
        theme="h1"
        alignment="center"
        colour="black"
        display="block"
      />
      <Spacer direction="vertical" amount="10px" display="block" />
      {user ? (
        <>
          <PrettyFormContainer
            title="Display Name"
            description={[]}
            formId="displayName"
            formContent={
              <TextInput
                initialValue={user.displayName}
                onTextChange={newValue =>
                  handleFormDataChange('displayName', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Email"
            description={['If you change your email, you will be logged out.']}
            formId="email"
            formContent={
              <TextInput
                initialValue={user.email}
                onTextChange={newValue =>
                  handleFormDataChange('email', newValue)
                }
              />
            }
          />
          <AlignmentContainer align="center" display="block">
            <Button
              text="Request Password Reset"
              theme="secondary"
              display="block"
              isDisabled={isApiBusy}
              callback={() => handlePasswordResetClick()}
            />
            <Spacer direction="vertical" amount="50px" display="block" />
            <Button
              text="Update Profile Information"
              theme="flair"
              display="block"
              isDisabled={isApiBusy}
              callback={() => handleSaveClick()}
            />
            <Spacer direction="vertical" amount="50px" display="block" />
            <Button
              text="Logout"
              theme="dark"
              display="block"
              isDisabled={isApiBusy}
              callback={() => handleLogoutClick()}
            />
          </AlignmentContainer>
        </>
      ) : (
        <Loading type="large" alignment="center" />
      )}
    </PageContainer>
  )
}

export default Profile
