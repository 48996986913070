import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router'

import { Section } from '../styled'
import { urls } from '../../../_utilities/config'
import TextElement from '../../../components/text/Text'
import Button from '../../../components/button/Button'
import Spacer from '../../../components/spacer/Spacer'
import { NotificationContext } from '../../../_globals/notifications/notification-context'
import { ApiResponse } from '../../../_types/api'
import { doFunctionsCall } from '../../../_globals/custom-firebase/custom-firebase'
import { CaseStatus } from '../../../_types/globals'
import { CaseDetailsProps } from './types'
import { DetailContainer, DetailsScrollContainer, HiddenInput } from './styled'
import { formatCreatedAtTimestamp, formatStatus } from './helpers'

/**
 * Displays the details for a case
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <Details caseData={caseData} />
 * ```
 */
const Details = ({
  caseData,
  userRole = 'guest',
}: CaseDetailsProps): JSX.Element => {
  const navigate = useNavigate()
  const { showNotification } = useContext(NotificationContext)
  const [caseStatus, setCaseStatus] = useState<CaseStatus>(caseData.status)

  const shareLink = useMemo(
    () => `${urls.userApp}/case/${caseData.id}`,
    [caseData.id],
  )

  const simplifiedUserRole = useMemo(() => {
    if (
      userRole === 'super-admin' ||
      userRole === 'admin' ||
      userRole === 'designer'
    ) {
      return 'editor'
    } else if (userRole === 'client') {
      return 'client'
    } else if (userRole === 'patient' || userRole === 'user') {
      return 'patient'
    }

    return 'guest'
  }, [userRole])

  const handleEditCaseClick = useCallback(() => {
    window.open(`${urls.adminPortal}/case/${caseData.id}`, `_self`)
  }, [caseData.id])

  const handleLoginClick = useCallback(() => {
    navigate('/login')
  }, [navigate])

  const handleCopyLink = useCallback(() => {
    const copyText = document.getElementById('share-link') as HTMLInputElement
    copyText.select()
    copyText.setSelectionRange(0, 99_999)

    navigator.clipboard.writeText(copyText.value)

    showNotification({
      title: 'Link copied!',
      type: 'success',
      dismissAfter: 3000,
    })
  }, [showNotification])

  const handleApproveCase = useCallback(() => {
    doFunctionsCall('HighestPriority', {
      signature: 'Case-ApproveCase',
      caseId: caseData.id,
    }).then((response: ApiResponse) => {
      if (response.code === 200) {
        showNotification({
          title: 'Case approved!',
          type: 'success',
          dismissAfter: 3000,
        })

        setCaseStatus('approved')
      } else {
        showNotification({
          title: 'Error approving case',
          type: 'error',
          dismissAfter: 3000,
        })
      }
    })
  }, [caseData?.id, showNotification])

  useEffect(() => {
    if (caseData.status) {
      setCaseStatus(caseData.status)
    }
  }, [caseData.status])

  return (
    <Section>
      <DetailsScrollContainer>
        {simplifiedUserRole === 'guest' ? (
          <DetailContainer>
            <TextElement text="Viewing as a Guest" theme="h3" display="block" />
            <TextElement
              text="You are viewing this case as a guest. You can only see non-specific data."
              theme="paragraph"
              display="block"
            />
            <Spacer direction="vertical" amount="10px" display="block" />
            <Button
              text="Login to view more details"
              theme="main"
              display="block"
              callback={() => handleLoginClick()}
            />
            <Spacer direction="vertical" amount="20px" display="block" />
          </DetailContainer>
        ) : null}
        <DetailContainer>
          <TextElement text="Status" theme="h3" display="block" />
          <TextElement
            text={formatStatus(caseStatus)}
            theme="paragraph"
            display="block"
          />
        </DetailContainer>
        <DetailContainer>
          <TextElement text="Details" theme="h3" display="block" />
          <TextElement
            text={caseData.displayCaseDetails}
            theme="paragraph"
            display="block"
          />
        </DetailContainer>
        {simplifiedUserRole === 'editor' ? (
          <DetailContainer>
            <TextElement text="Internal Details" theme="h3" display="block" />
            <TextElement
              text="The patient cannot see these details"
              theme="paragraph"
              italics={true}
              display="block"
            />
            <TextElement
              text={caseData.internalCaseDetails}
              theme="paragraph"
              display="block"
            />
          </DetailContainer>
        ) : null}
        <DetailContainer>
          <TextElement text="Case Created" theme="h3" display="block" />
          <TextElement
            text={formatCreatedAtTimestamp(caseData.createdAtTimestamp)}
            theme="paragraph"
            display="block"
          />
        </DetailContainer>
        <DetailContainer>
          <TextElement
            text="Internal Reference ID"
            theme="h3"
            display="block"
          />
          <TextElement
            text={caseData.friendlyId}
            theme="paragraph"
            display="block"
          />
        </DetailContainer>
        {simplifiedUserRole !== 'guest' ? (
          <>
            <DetailContainer>
              <TextElement
                text="Anatomiz3D Case ID"
                theme="h3"
                display="block"
              />
              <TextElement
                text={caseData.anatomiz3dId || 'None Specified'}
                theme="paragraph"
                display="block"
              />
            </DetailContainer>
            <DetailContainer>
              <TextElement
                text="Client Reference ID"
                theme="h3"
                display="block"
              />
              <TextElement
                text={caseData.institutionId || 'None Specified'}
                theme="paragraph"
                display="block"
              />
            </DetailContainer>
          </>
        ) : null}
        {(simplifiedUserRole === 'client' || simplifiedUserRole === 'editor') &&
        caseStatus !== 'approved' ? (
          <DetailContainer>
            <TextElement text="Case Status" theme="h3" display="block" />
            <Spacer direction="vertical" amount="10px" display="block" />
            <Button
              text="Approve Case"
              theme="main"
              display="block"
              callback={() => handleApproveCase()}
            />
          </DetailContainer>
        ) : null}
        {simplifiedUserRole === 'editor' ? (
          <DetailContainer>
            <TextElement text="Edit Case" theme="h3" display="block" />
            <Spacer direction="vertical" amount="10px" display="block" />
            <Button
              text="Edit this case on the Admin Portal"
              theme="main"
              display="block"
              callback={() => handleEditCaseClick()}
            />
            <Spacer direction="vertical" amount="20px" display="block" />
          </DetailContainer>
        ) : null}
        {simplifiedUserRole !== 'guest' ? (
          <DetailContainer>
            <Spacer direction="vertical" amount="50px" display="block" />
            <TextElement text="Share this case" theme="h3" display="block" />
            <TextElement
              text="Private information will not be shared."
              theme="paragraph"
              italics={true}
            />
            <HiddenInput
              value={shareLink}
              id="share-link"
              disabled
              readOnly
              onClick={() => handleCopyLink()}
            />
            <Button
              text="Copy link"
              theme="main"
              display="block"
              callback={() => handleCopyLink()}
            />
            <Spacer direction="vertical" amount="10px" display="block" />
          </DetailContainer>
        ) : null}
      </DetailsScrollContainer>
    </Section>
  )
}

export default Details
