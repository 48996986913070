import { defaultCameraPosition } from '../../_defaults/model'
import { CameraState } from '../../_types/model'
import { deepCopy } from '../../_utilities/utils'

/**
 * Check if the camera position is valid
 *
 * @param {CameraState} cameraPosition - The camera position to check
 * @returns {boolean} - Whether the camera position is valid
 *
 * @example
 * ```ts
 * isCameraPositionValid(cameraPosition)
 * ```
 */
const isCameraPositionValid = (cameraPosition: CameraState) =>
  !(
    Math.abs(cameraPosition.position.x) < 10 ||
    Math.abs(cameraPosition.position.y) < 10 ||
    Math.abs(cameraPosition.position.z) < 10
  )

/**
 * Get a sanitized camera position
 *
 * @param {CameraState} cameraPosition - The camera position to sanitize
 * @returns {CameraState} - The sanitized camera position
 *
 * @example
 * ```ts
 * const cameraPosition = getSanitizedCameraPosition(cameraPosition)
 * ```
 */
const getSanitizedCameraPosition = (cameraPosition: CameraState): CameraState =>
  isCameraPositionValid(cameraPosition)
    ? cameraPosition
    : { ...cameraPosition, position: { x: 50, y: 50, z: 50 } }

/**
 * Get a predefined snapped camera position
 *
 * @param {string} position - The position to snap to
 * @returns {CameraState} - The snapped camera position
 *
 * @example
 * ```ts
 * const cameraPosition = getSnappedCameraPosition('front')
 * ```
 */
const getSnappedCameraPosition = (position: string): CameraState => {
  const newCameraPosition: CameraState = deepCopy(defaultCameraPosition)

  newCameraPosition.target = {
    x: 0,
    y: 0,
    z: 0,
  }
  newCameraPosition.up = {
    x: 0,
    y: 1,
    z: 0,
  }

  if (position === 'front') {
    newCameraPosition.position = {
      x: 200,
      y: 0,
      z: 0,
    }
  } else if (position === 'back') {
    newCameraPosition.position = {
      x: -200,
      y: 0,
      z: 0,
    }
  } else if (position === 'left') {
    newCameraPosition.position = {
      x: 0,
      y: 0,
      z: 200,
    }
  } else if (position === 'right') {
    newCameraPosition.position = {
      x: 0,
      y: 0,
      z: -200,
    }
  } else if (position === 'top') {
    newCameraPosition.position = {
      x: 0,
      y: 200,
      z: 0,
    }
  } else if (position === 'bottom') {
    newCameraPosition.position = {
      x: 0,
      y: -200,
      z: 0,
    }
  }

  return newCameraPosition
}

export {
  isCameraPositionValid,
  getSanitizedCameraPosition,
  getSnappedCameraPosition,
}
