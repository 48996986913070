import styled from 'styled-components'

import { colours, shadow } from '../../../_globals/theme'

const ModelListingContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 250px);

  @media (max-width: 850px) {
    height: auto;
    overflow-y: unset;
  }
`

const ModelListingHeader = styled.div`
  position: relative;
  width: 100%;
  border: none;
  background: none;
  padding-left: 10px;
  display: flex;

  & > div:first-of-type {
    flex: 1;
    width: 100%;
  }
`

const HeaderIcon = styled.button`
  padding: 5px 20px;
  cursor: pointer;
  width: 15px;
  height: 15px;
  transition: opacity 0.3s;
  background: none;
  border: none;

  &:hover {
    opacity: 0.5;
  }
`

const ModelSettings = styled.div`
  margin: 20px 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: ${shadow.main};
`

const LabelContainer = styled.div`
  display: flex;
  margin-top: 10px;
`

const Label = styled.label`
  flex: 1;
`

const LabelInput = styled.input`
  padding: 2px 5px;
  width: 75px;
  margin-right: 10px;
  border: 1px solid ${colours.lowlight};
  border-radius: 3px;
`

export {
  ModelSettings,
  LabelContainer,
  Label,
  LabelInput,
  ModelListingContainer,
  ModelListingHeader,
  HeaderIcon,
}
