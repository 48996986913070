import styled from 'styled-components'

const Link = styled.a`
  display: flex;
  cursor: pointer;
  padding: 0 10px;
  align-items: center;

  & svg {
    transform: scale(0.7);

    @media screen and (max-width: 300px) {
      display: none;
    }
  }
`

export { Link }
