import React, { useCallback, useMemo, useState } from 'react'
import XDate from 'xdate'

import Loading from '../../components/loading/Loading'
import PageContainer from '../../components/page-container/PageContainer'
import { DatabaseCase } from '../../_types/case'
import Spacer from '../../components/spacer/Spacer'
import TextElement from '../../components/text/Text'
import { doFunctionsCall } from '../../_globals/custom-firebase/custom-firebase'
import { ApiResponse } from '../../_types/api'
import { TableData } from '../../components/table/types'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import { convertStatusToTitle } from './helpers'

/**
 * Displays the home page
 * @returns {JSX.Element}
 *
 * @example
 * ```tsx
 * <Home />
 * ```
 */
const Home = (): JSX.Element => {
  const [cases, setCases] = useState<DatabaseCase[]>([])
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)
  const [pageError, setPageError] = useState<string>('')

  const handleViewCase = useCallback((caseId: string) => {
    window.open(`${window.location.origin}/case/${caseId}`, `_self`)
  }, [])

  const tableHeaders = useMemo<TableData[]>(
    () => [
      { content: 'Title' },
      { content: 'Status' },
      { content: 'Created' },
      { content: 'Anatomiz3D Case ID' },
      { content: 'Client Reference ID' },
      { content: 'View Case' },
    ],
    [],
  )

  const tableContent = useMemo<TableData[][]>(() => {
    const data: TableData[][] = []

    cases.forEach(caseData => {
      const row: TableData[] = [
        { content: caseData.title },
        { content: convertStatusToTitle(caseData.status) },
        {
          content: new XDate(caseData.createdAtTimestamp).toString(
            'MMM dd, yyyy',
          ),
        },
        { content: caseData.anatomiz3dId, useHiddenElement: true },
        { content: caseData.institutionId, useHiddenElement: true },
        {
          content: (
            <Button
              callback={() => handleViewCase(caseData.id)}
              text="View"
              theme="secondary"
              display="inline-block"
              size="small"
            />
          ),
        },
      ]

      data.push(row)
    })

    return data
  }, [cases, handleViewCase])

  const handlePageReady = useCallback(() => {
    doFunctionsCall('Admin', {
      signature: 'Case-GetAll',
    })
      .then((response: ApiResponse) => {
        if (response.code === 200) {
          const fetchedCases = JSON.parse(response.data) as DatabaseCase[]

          setCases(fetchedCases)
          setIsApiBusy(false)
        } else if (response.code === 500) {
          console.error(response)
          setPageError(
            'There was an error fetching your cases. Please try again later.',
          )
        }
      })
      .catch((error: Error) => {
        console.error(error)
        setPageError(
          'There was an error fetching your cases. Please try again later.',
        )
      })
  }, [])

  return (
    <PageContainer
      offsetLeft="20px"
      offsetRight="20px"
      offsetBottom="20px"
      offsetTop="20px"
      offsetMode="padding"
      width="unset"
      height="unset"
      allowedRoles={['super-admin', 'admin', 'designer', 'user']}
      allowUnauthenticated={false}
      allowNotifications={true}
      pageError={pageError}
      showSidebar={true}
      onPageReady={() => handlePageReady()}>
      <Spacer direction="vertical" amount="20px" display="block" />
      <TextElement
        text="Welcome to Anatomiz3D Viewer"
        theme="h1"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="20px" display="block" />
      {isApiBusy ? <Loading type="large" /> : null}
      {isApiBusy === false && cases.length === 0 ? (
        <TextElement
          text="You have no cases yet."
          theme="h2"
          alignment="center"
          display="block"
        />
      ) : null}
      {isApiBusy === false && cases.length > 0 ? (
        <Table
          alignment="center"
          headerDirection="horizontal"
          size="large"
          width="1000px"
          headers={tableHeaders}
          content={tableContent}
        />
      ) : null}
    </PageContainer>
  )
}

export default Home
