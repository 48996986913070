/* eslint-disable @typescript-eslint/no-explicit-any */
import XDate from 'xdate'
import MobileDetect from 'mobile-detect'
import numberFormatter, { IFormatNumberOptions } from 'format-number'

import { GenericObject, UserClaimRoles } from '../_types/globals'

/**
 * Check if a variable exists
 *
 * @param {unknown} valueToCheck - Variable to check
 * @returns {boolean}
 *
 * ```ts
 * if(doesExist(result.data)) { }
 * ```
 */
const doesExist = (valueToCheck: unknown): boolean =>
  !(typeof valueToCheck === 'undefined' || valueToCheck == null)

/**
 * Preform a deep copy of a variable
 *
 * @param {any} valueToCopy - The variable to copy
 * @returns {any}
 *
 * ```ts
 * const productCopy = deepCopy(product)
 * ```
 */
const deepCopy = (valueToCopy: any): any =>
  JSON.parse(JSON.stringify(valueToCopy))

/**
 * Generate a random number
 *
 * @param {boolean} [wholeNumber=false] - If the number should be an integer
 * @param {number} [min=1] - Lowest possible number
 * @param {number} [max=10] - Highest possible number
 * @returns {number}
 *
 * ```ts
 * const randomIndex = randomNumber(true, 0, 10)
 * ```
 */
const randomNumber = (wholeNumber = false, min = 1, max = 10): number => {
  const number = Math.random() * (max - min) + min
  return wholeNumber ? Math.round(number) : number
}

/**
 * Get a default date or time value
 *
 * @param {('date' | 'time')} dateOrTime - 'date' or 'time'
 * @returns {Date}
 *
 * ```ts
 * const defaultTime = getDefaultDateOrTime('time')
 * ```
 */
const getDefaultDateOrTime = (dateOrTime: 'date' | 'time'): Date => {
  const currentDate = new XDate()

  if (dateOrTime === 'date') {
    return new XDate(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDay(),
      1,
      2,
      34,
      567,
    ).toDate()
  }

  return new XDate(
    1990,
    1,
    2,
    currentDate.getHours(),
    currentDate.getMinutes(),
    currentDate.getSeconds(),
  ).toDate()
}

/**
 * Check if a date or time value is a default
 *
 * @param {('date' | 'time')} dateOrTime - 'date' or 'time'
 * @param {Date} date - The date/time variable
 * @returns {boolean}
 *
 * ```ts
 * const isDefault = checkIfDefaultDateOrTime('date', company.signupDate)
 * ```
 */
const checkIfDefaultDateOrTime = (dateOrTime: 'date' | 'time', date: Date) => {
  const dateToCheck = new XDate(date)

  if (dateOrTime === 'date') {
    const defaultDate = getDefaultDateOrTime('date')

    if (
      dateToCheck.getHours() === defaultDate.getHours() &&
      dateToCheck.getMinutes() === defaultDate.getMinutes() &&
      dateToCheck.getSeconds() === defaultDate.getSeconds() &&
      dateToCheck.getMilliseconds() === defaultDate.getMilliseconds()
    ) {
      return true
    }

    return false
  }

  const defaultTime = getDefaultDateOrTime('time')

  if (
    dateToCheck.getFullYear() === defaultTime.getFullYear() &&
    dateToCheck.getMonth() === defaultTime.getMonth() &&
    dateToCheck.getDay() === defaultTime.getDay()
  ) {
    return true
  }

  return false
}

/**
 * Uniformly format an error object
 *
 * @param {unknown} error - The error object
 * @returns {any}
 *
 * ```ts
 * return(formatErrorObject(error))
 * ```
 */
const formatErrorObject = (error: unknown): any =>
  typeof error === 'object' ? error : { error: error.toString() }

/**
 * Check if the user is using a mobile device
 *
 * @returns {boolean}
 *
 * ```ts
 * const isMobile = isMobileDevice()
 * ```
 */
const isMobileDevice = (): boolean => {
  const currentDevice = new MobileDetect(window.navigator.userAgent)
  const mobileDeviceBrand = currentDevice.mobile()

  return mobileDeviceBrand && mobileDeviceBrand !== ''
}

/**
 * Transform an object with weak typings to strong typings
 *
 * @param {unknown} defaultObject - The default object from _defaults/
 * @param {*} objectToTransform - The object to transform
 * @param {('recover' | 'ignore' | 'treat-as-missing')} malformedAttributeStrategy - What to do with malformed data
 * @param {('default' | 'null' | 'undefined' | 'ignore')} missingAttributeStrategy - What to do with missing data
 * @returns {any}
 *
 * ```ts
 * const typedObject = transformToType(defaultDisplayProduct, selectedProduct, 'recover', 'default')
 * ```
 */
const transformToType = (
  defaultObject: unknown,
  objectToTransform: any,
  malformedAttributeStrategy: 'recover' | 'ignore' | 'treat-as-missing',
  missingAttributeStrategy: 'default' | 'null' | 'undefined' | 'ignore',
): any => {
  type returnType = typeof defaultObject
  const indexableDefaultObject = defaultObject as any
  const builder: GenericObject = {
    initializer: null,
  }

  const attributes = Object.keys(defaultObject)
  attributes.forEach(attribute => {
    if (attribute in objectToTransform) {
      if (
        typeof indexableDefaultObject[attribute] ===
        typeof objectToTransform[attribute]
      ) {
        // already the correct type, run with it
        builder[attribute] = objectToTransform[attribute]
      } else if (malformedAttributeStrategy === 'recover') {
        // attempt to recover an incorrectly typed value

        if (typeof indexableDefaultObject[attribute] === 'boolean') {
          if (
            objectToTransform[attribute].toString().toLowerCase() === 'true' ||
            objectToTransform[attribute].toString() === '1'
          ) {
            builder[attribute] = true
          } else if (
            objectToTransform[attribute].toString().toLowerCase() === 'false' ||
            objectToTransform[attribute].toString() === '0'
          ) {
            builder[attribute] = false
          }
        } else if (typeof indexableDefaultObject[attribute] === 'number') {
          if (/^-?[\d.]+$/giu.test(objectToTransform[attribute].toString())) {
            builder[attribute] = Number.parseFloat(
              objectToTransform[attribute].toString(),
            )
          }
        } else if (typeof indexableDefaultObject[attribute] === 'string') {
          builder[attribute] = objectToTransform[attribute].toString()
        }
      } else if (malformedAttributeStrategy === 'ignore') {
        // wrong type, but we'll add it to the object anyway
        builder[attribute] = objectToTransform[attribute]
      } else if (malformedAttributeStrategy === 'treat-as-missing') {
        // wrong type, we'll use the value from the default object
        delete objectToTransform[attribute]
      }
    }

    if (
      missingAttributeStrategy !== 'ignore' &&
      !(attribute in objectToTransform)
    ) {
      // the attribute doesn't event exist on the object

      switch (missingAttributeStrategy) {
        case 'default':
          builder[attribute] = indexableDefaultObject[attribute]
          break
        case 'null':
          builder[attribute] = null
          break
        case 'undefined':
          // eslint-disable-next-line no-undefined
          builder[attribute] = undefined
          break
        default:
          builder[attribute] = indexableDefaultObject[attribute]
          break
      }
    }
  })

  return builder as returnType
}

/**
 * Shake it up
 *
 * @param {any[]} array - The array to shuffle
 * @returns {any[]}
 *
 * ```ts
 * const randomProducts = shuffleList(products)
 * ```
 */
const shuffleList = (array: any[]) => {
  // Fisher-Yates Shuffle

  let currentIndex = array.length
  let randomIndex = 0

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

/**
 * Generate a background animation to be used in css
 *
 * @param {('colour' | 'greyscale')} theme - 'colour' or 'greyscale'
 * @returns {string[]}
 *
 * ```css
 * .background{
 *  background: ${generateBackgroundAnimation('colour')};
 * }
 * ```
 */
const generateBackgroundAnimation = (
  theme: 'colour' | 'greyscale',
): string[] => {
  const isNegative = randomNumber(true, 1, 2) === 1
  const angle = randomNumber(true, 1, 180)
  const possibleColours =
    theme === 'colour' ? ['#393186', '#a2a3e9'] : ['#262626', '#333232']
  const builder: string[] = [
    `${isNegative ? '-' : ''}${angle.toString()}deg`,
    ...shuffleList(possibleColours),
  ]

  return builder
}

/**
 * Generate an ID
 *
 * @param {number} idLength - Length of the id
 * @param {('numeric' | 'letters' | 'any')} [mode] - The useable characters
 * @returns {string}
 *
 * ```ts
 * product.id = generateId(10)
 * ```
 */
const generateId = (
  idLength: number,
  mode?: 'numeric' | 'letters' | 'any',
): string => {
  let id = ''
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

  if (mode === 'numeric') {
    possible = '0123456789'
  } else if (mode === 'letters') {
    possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  }

  for (let index = 0; index < idLength; index++) {
    id += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return id
}

/**
 * Format a number
 *
 * @param {number} value - The number to format
 * @param {number} decimals - Number of decimals to force
 * @param {IFormatNumberOptions} [options] - Formatting options
 * @returns {string}
 *
 * ```ts
 * const formattedNumber = formatNumber(product.cost, 2)
 * ```
 */
const formatNumber = (
  value: number,
  decimals: number,
  options?: IFormatNumberOptions,
) => {
  const sanitizedOptions: IFormatNumberOptions = { ...options }

  if (decimals && !options?.round) {
    sanitizedOptions.round = decimals
  }
  if (decimals && !options?.truncate) {
    sanitizedOptions.truncate = decimals
  }

  let formattedNumber = numberFormatter(sanitizedOptions)(value)

  if (decimals > 0) {
    if (!formattedNumber.includes('.')) {
      formattedNumber += '.'
    }

    const existingDecimals = formattedNumber.replace(/^.+\./giu, '').length

    if (existingDecimals < decimals) {
      for (let index = 0; index < decimals - existingDecimals; index++) {
        formattedNumber += '0'
      }
    }
  }

  return formattedNumber
}

/**
 * Return a strongly-typed number
 *
 * @param {(string | number)} value - The value to parse
 * @returns {number}
 *
 * ```ts
 * product.cost = parseNumber(product.cost)
 * ```
 */
const parseNumber = (value: string | number): number =>
  Number.parseFloat(value.toString())

/**
 * Get the viewport width
 *
 * @returns {number}
 *
 * ```ts
 * const windowWidth = getWindowWidth()
 * ```
 */
const getWindowWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth

/**
 * Get the viewport height
 *
 * @returns {number}
 *
 * ```ts
 * const windowHeight = getWindowHeight()
 * ```
 */
const getWindowHeight = () =>
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight

/**
 * Attempt to find the "name" attribute of an object
 *
 * @param {GenericObject} genericObject - The object to search through
 * @returns {string}
 *
 * ```ts
 * const productName = product[determineObjectNameAttribute(product)]
 * ```
 */
const determineObjectNameAttribute = (genericObject: GenericObject) => {
  const keys = Object.keys(genericObject)
  const potentialNames = keys
    .filter(key => key.toLowerCase().includes('name'))
    .sort((a, b) => {
      if (a.length > b.length) return 1
      else if (a.length < b.length) return -1
      return 0
    })

  return potentialNames[0] || ''
}

/**
 * Attempt to find the "id" attribute of an object
 *
 * @param {GenericObject} genericObject - The object to search through
 * @returns {string}
 *
 * ```ts
 * const productId = product[determineObjectIdAttribute(product)]
 * ```
 */
const determineObjectIdAttribute = (genericObject: GenericObject) => {
  const keys = Object.keys(genericObject)
  const potentialNames = keys
    .filter(key => key.toLowerCase().includes('id'))
    .sort((a, b) => {
      if (a.length > b.length) return 1
      else if (a.length < b.length) return -1
      return 0
    })

  return potentialNames[0] || ''
}

/**
 * Transform a database-typed object into a display-typed object
 *
 * @param {*} databaseObject - The database object to transform
 * @param {*} defaultDisplayObject - The default display object from _defaults/
 * @returns {any}
 *
 * ```ts
 * const displayProduct = transformDatabaseIntoDisplay(fetchedProduct, defaultDisplayProduct)
 * ```
 */
const transformDatabaseIntoDisplay = (
  databaseObject: any,
  defaultDisplayObject: any,
): any => {
  const formattedObject: any = {}

  Object.keys(defaultDisplayObject).forEach((key: string) => {
    if (key in databaseObject) {
      formattedObject[key] = databaseObject[key]
    } else {
      formattedObject[key] = defaultDisplayObject[key]
    }
  })

  return formattedObject
}

/**
 * Cut off long text in a friendly way
 *
 * @param {string} text - The text to potentially trim
 * @param {number} maxLength - The max length before cutting
 * @returns {string}
 *
 * ```tsx
 *  <Description>{cutOffText(product.description, 150)}</Description>
 * ```
 */
const cutOffText = (text: string, maxLength: number): string => {
  if (!doesExist(text)) {
    return ''
  }

  return text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`
}

/**
 * Convert a role to a title
 *
 * @param {UserClaimRoles} role - The role to convert
 * @returns {string}
 *
 * ```ts
 * const title = convertRoleToTitle('super-admin')
 * ```
 */
const convertRoleToTitle = (role: UserClaimRoles) => {
  if (role === 'super-admin') {
    return 'Super Admin'
  } else if (role === 'admin') {
    return 'Admin'
  } else if (role === 'designer') {
    return 'Designer'
  } else if (role === 'user') {
    return 'User'
  }

  return 'User'
}

/**
 * Get the text color for a background color
 *
 * @param {string} hex - The hex color to check
 * @returns {string}
 *
 * ```ts
 * const textColor = getTextFromBackground('#ffffff')
 * ```
 */
const getTextFromBackground = (hex: string) => {
  const red = Number.parseInt(hex.slice(1, 3), 16)
  const green = Number.parseInt(hex.slice(3, 5), 16)
  const blue = Number.parseInt(hex.slice(5, 7), 16)

  const yiq = (red * 299 + green * 587 + blue * 114) / 1000

  return yiq >= 128 ? 'black' : 'white'
}

export {
  doesExist,
  deepCopy,
  randomNumber,
  getDefaultDateOrTime,
  checkIfDefaultDateOrTime,
  formatErrorObject,
  isMobileDevice,
  transformToType,
  shuffleList,
  generateBackgroundAnimation,
  generateId,
  formatNumber,
  parseNumber,
  getWindowWidth,
  getWindowHeight,
  determineObjectNameAttribute,
  determineObjectIdAttribute,
  transformDatabaseIntoDisplay,
  cutOffText,
  convertRoleToTitle,
  getTextFromBackground,
}
