import appPackage from '../../package.json'
import { AppEnvironment } from '../_environments/types'
import {
  Environment as loadedEnvironment,
  Environment,
} from '../_environments/environment'
import { log } from './logging'
import { formatErrorObject } from './utils'

/**
 * Get configuration information about the webapp
 *
 * @returns {any}
 *
 *  ```ts
 * const appInfo = getAppInfo()
 * ```
 */
const getAppInfo = () => {
  let numberOfDependencies = -1

  try {
    const deps = Object.keys(appPackage.dependencies)
    numberOfDependencies = deps.length
  } catch (error) {
    log(
      'config.ts',
      'getAppInfo',
      'error',
      'Error fetching ',
      formatErrorObject(error),
      true,
    )
    numberOfDependencies = -1
  }

  return {
    appVersion: appPackage.version ?? 'unknown',
    appName: appPackage.name ?? 'unknown',
    environment: loadedEnvironment.ENV_CODE,
    numberOfDependencies,
  }
}

/**
 * Fetch the devices timezone
 *
 * @returns {string}
 *
 * ```ts
 * const timezone = getTimezone()
 * ```
 */
const getTimezone = () => {
  // eslint-disable-next-line new-cap
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return timezone
}

/**
 * Get environment settings for the webapp
 *
 * @returns {AppEnvironment}
 *
 * ```ts
 * getEnvironmentSettings()
 * ```
 */
const getEnvironmentSettings = (): AppEnvironment =>
  Environment ?? {
    ENV_CODE: 'local',
    ENV_NAME: 'Local',
    consoleLogLevel: 3,
    FUNCTIONS_REGION: 'northamerica-northeast1',
    config: {
      apiKey: 'AIzaSyCXVR_fDb1LOXegtNwNv_mbL79DxZ_3Jxg',
      authDomain: 'd-viewer-b6081.firebaseapp.com',
      projectId: 'd-viewer-b6081',
      storageBucket: 'd-viewer-b6081.appspot.com',
      messagingSenderId: '253425981755',
      appId: '1:253425981755:web:d6405a10905670e4abff1e',
      measurementId: 'G-RNJ503YHES',
    },
  }

const dateFormats = {
  fullMonthDayYear: 'MMMM d, yyyy',
  numericDayMonthYear: 'dd-MM-yyyy',
}

const commonRegex = {
  email: /^[A-Z0-9-._]+@[A-Z0-9-.]+$/giu,
  phone: /^(\+[0-9]{1,4}[.\-\s]?)?[0-9]{3}[.\-\s]?[0-9]{3}[.\-\s]?[0-9]{4}$/giu,
}

const urls = {
  adminPortal: 'https://anatomiz3d-admin.web.app',
  userApp: 'https://anatomiz3d.web.app',
}

export {
  getAppInfo,
  getTimezone,
  getEnvironmentSettings,
  dateFormats,
  commonRegex,
  urls,
}
